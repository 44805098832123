<template>
  <div style="width:600px; display:none;">
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="活动名称">
        <el-input v-model="message" type="textarea" :rows="5"></el-input>
      </el-form-item>
      <el-form-item label="姓名">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input v-model="form.phone"></el-input>
      </el-form-item>
      <el-form-item label="地址">
        <el-input v-model="form.address"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="parseAddress">识别</el-button>
        <el-button>取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import AddressParse from 'address-parse'
export default {
  name: 'Detail',
  data() {
    return {
      message: '',
      form: {
        name: '',
        phone: '',
        address: ''
      }
    }
  },
  methods: {
    parseAddress() {
      if(this.message != '') {
        const result = AddressParse.parse(this.message)
        console.log(result,6666)
        this.form.name = result[0].name;
        this.form.phone = result[0].mobile;
        this.form.address = `${result[0].province}-${result[0].city}-${result[0].area}`+result[0].details;
      }
    },
    getDetail(row) {
      console.log(row)
    },
    reset(done) {
      this.isChange = false
      this.$refs.elFormDom.resetFields()
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
  }
}
</script>