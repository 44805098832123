<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" size="mini">
        <el-form-item label="反馈来源:" prop="role_type">
          <el-select v-model="table.params.role_type" placeholder="请选择" @change="getTable">
            <el-option label="需求端" :value="1"></el-option>
            <el-option label="师傅端" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="注册号码:" prop="account">
          <el-input clearable v-model.trim="table.params.account" placeholder="请输入号码"></el-input>
        </el-form-item>
        <el-form-item label="反馈内容:" prop="content">
          <el-input clearable v-model="table.params.content" placeholder="请输入关键字"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="theneBtn" type="primary" icon="el-icon-search" @click="onSearch">查询 </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:role_type="{row}">
        <span>{{row.role_type == 1 ? '需求端' : '师傅端'}}</span>
      </template>
      <template v-slot:image="{row}">
        <el-image
          v-if="!!row.image"
          style="width: 40px; height: 40px; line-height: 30px;"
          :src="row.image"
          fit="cover"
          :preview-src-list="[row.image]">
        </el-image>
      </template>
    </VTable>

    <detail ref="detail"></detail>
    
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Detail from './components/Detail.vue';
import { mixinTable } from '@/mixins/table.js';
export default {
  name: 'BannerList',
  components: {
    VTable,
    Detail
  },
  mixins: [mixinTable],
  data() {
    return {
      field: [
        { name: "role_type", label: "用户来源", hidden: false },
        { name: "account", label: "注册号码", hidden: false },
        { name: "content", label: "反馈内容", showTooltip: true, hidden: false },
        { name: "image", label: "图片", hidden: false },
        { name: "create_time", label: "反馈时间", hidden: false },
        // { name: "action", label: "操作", fixed:"right", width: "200", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          role_type: '', //来源 1:需求 2:师傅
          account: '',
          content: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      }
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/flex_feedback/list', { params:this.table.params }).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 查看
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    delete(rowid) {
      this.$http.post('/admin/flex_kind/delete', { id: rowid }).then(res => {
        if(res.code === 1) {
          this.$message.success('操作成功！')
          this.getTable();
        } else {
          this.$message.error(res.data)
        }
      })
    },
  }
}
</script>